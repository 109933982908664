import { BodyTitle, SubText } from "../../atoms/Text";
import { styled } from "@mui/system";
import { Button } from "../../atoms/Button";
import useLocalStorage from "../../../hooks/localstorage";
import { useQuery } from "react-query";
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Analyzation,
  Patients,
} from "@sleepwell_new_platform/common-types";
import { DataGrid as MuiDataGrid } from "./DataGrid";
import { SimpleBackdrop } from "../../molecules/Backdrop";
import { getPatients } from "../../../aws/api/patients";
import { EditModal, EditWideModal, Modal, PdfDownloadModal } from "../../molecules/Modal";
import { Progress } from "../../atoms/Progress";
import { HeadlessTable } from "../../molecules/Table";
import {
  analyzationDataColums,
  analyzationResultColumns,
  attributeColumns,
} from "../../../static/analyticsRequest";
import MuiLink from "@mui/material/Link";
import { getAnalyzationStatusLabel } from "../../../utils/analyzations";
import { ArrowIcon } from "../../../icons/analyzation";
import { DownloadIcon } from "../../atoms/icons/DownloadIcon";
import { Accordion } from "../../molecules/Accordion";
import { AnalyzationResult } from "@sleepwell_new_platform/common-types/src/analyzationResults";
import { getSleepCycle } from "../analyzations/Analyzations";
import { SleepAnalyticsDetail } from "../../templates/SleepAnalyticsDetail";
import Timeseries from "../analyzations/Timeseries";
import dayjs from "dayjs";
import { SleepAnalyticsDocAReport } from "../../templates/SleepAnalyticsDocAReport";
import { SleepAnalyticsDocA3Report } from "../../templates/SleepAnalyticsDocA3Report";
import { SelectInput } from "../../molecules/Select";
import { convertToCSVFormat } from "../../../utils/analyzations/convertToCSVFormat";
import { exportCSV } from "../../../utils/exportCSV";
import { Box } from "@mui/material";
import JSZip from "jszip";
import {
  AnalyzationStatus
} from "../../../utils/analyzations/analyzations";
import CustomizedSnackbar from "../../atoms/CustomizedSnackbar";
import { formatDateToYYYYMMDDHHMMSS } from "../../../utils/Moment";
import { roundToSecondDecimalPlace } from "../../../utils/analyzations/roundFunctions";
import { GridSortModel } from "@mui/x-data-grid";
import OpenSearchClient from "@sleepwell_new_platform/common-functions/lib/opensearch";

type Props = {
  patientId: string;
  analyzationIds?: string[];
};

export const PersonalAnalyzations = (props: Props) => {
  const { patientId, analyzationIds } = props;
  const Api = new SleepwellApi(axios);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [clinicId] = useLocalStorage("clinicId", "");
  const [analyzationState, setAnalyzationState] = useState<Analyzation>();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [snackState, setSnackState] = useState<{ open: boolean, message: string, success: boolean }>({ open: false, message: "", success: false });
  const [selectList, setSelectList] = useState<string[]>([]);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);
  const [openPdfDownloadModal, setOpenPdfDownloadModal] = useState<boolean>(false);
  const [fftDownload, setFftDownload] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 300,
  });
  const [selectedAnalyzations, setSelectedAnalyzations] = useState<
    Analyzation[]
  >([]);
  const [selectedAnalyzationResults, setSelectedAnalyzationResults] = useState<
    AnalyzationResult[]
  >([]);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [openDocA4ReportModal, setOpenDocA4ReportModal] =
    useState<boolean>(false);
  const [openDocA3ReportModal, setOpenDocA3ReportModal] =
    useState<boolean>(false);
  const [openTimeSeriesModal, setOpenTimeSeriesModal] =
    useState<boolean>(false);
  const [isLoadingTimeSerisData, setIsLoadingTimeSerisData] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [analyzationsData, setAnalyzationsData] = useState<Analyzation[]>([]);
  const [total, setTotal] = useState<number>(0);
  const OpenSearch = useMemo(() => new OpenSearchClient(axios), []);
  const [sortModel, setSortModel] = useState<GridSortModel>([
      { field: 'firstAnalyzeRequestDate', sort: 'desc' },
    ]);
    const fetchMoreData = useCallback(async (data? : {[key: string]: string | number | null | undefined}) => {
      if(loading) return;
      if(!clinicId) return;
      setLoading(true);
      try {
        let query;
          query = {
            query: {
              bool: {
                must: [
                  { match: { isDeleted: 0 } },
                  { match: { clinicId: clinicId } },
                  { match: { patientId: patientId } },
                ]
              },
            },
            sort:  [{ firstAnalyzeRequestDate: { order: 'desc' } }],
            size: paginationModel.pageSize,
            from: paginationModel.page * paginationModel.pageSize,
          };
        const response = await OpenSearch.search<Analyzation>('new_analyzations', query);
        const newRecord = response.hits.hits.filter(hit => hit._source !== undefined).map((hit) => hit._source as Analyzation);
        setAnalyzationsData(newRecord);
        setTotal(typeof response.hits.total === 'number' ? response.hits.total : response.hits.total?.value ?? 0);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }, [OpenSearch, loading, paginationModel.page, paginationModel.pageSize, clinicId, patientId]);

  const { data: patientsResult } = useQuery("patients", () =>
    getPatients(clinicId).then((res) => res.data)
  );
  const { data: affiliation } = useQuery("affiliations", () =>
    Api.getAffiliations(clinicId).then((res) => res.data)
  );
  const { data: averageItems } = useQuery(
    "averageItems",
    async () => await Api.getAverageItems()
  );

  const id = useMemo(() => {
    return localStorage?.id ?? "";
  }, []);

  const { data: diseases } = useQuery("diseases", () =>
    Api.getDiseases().then((res) => res.data.result.Items)
  );

  const { data: analyzationResult, refetch: refetchAnalyzationResult } =
    useQuery("analyzationResults", async () => {
      if (!analyzationState) return;
      const res = await Api.getAnalyzationResult(analyzationState.id);
      return res.data;
    });
  const analyzationResultData = useMemo(() => {
    return analyzationResult?.result.Items[0] ?? null;
  }, [analyzationResult]);

  const { data: clinicData } = useQuery(
    "clinic",
    async () => await Api.getClinics(clinicId)
  );
  useEffect(() => {
    fetchMoreData();
  }, [paginationModel]);

  useEffect(() => {
    if (analyzationState?.id) refetchAnalyzationResult();
  }, [analyzationState]);

  const handleDownloadEDF = async (analyaztionData: Analyzation) => {
    setIsDisabled(true);
    const response = await Api.getSignedUrl(
      analyaztionData.edfFileName,
      analyaztionData.patientId,
      clinicId,
      "get"
    );
    const url = response.data.result;
    setIsDisabled(false);
    if (!url) return;
    window.open(url);
  };
  const handleDownloadSingleFFT = async (analyzationData: Analyzation) => {
    setIsDisabled(true);
    const response = await Api.getSignedUrl(
      analyzationData.edfFileName.replace(".EDF", ".txt"),
      analyzationData.patientId,
      analyzationData.clinicId,
      "get"
    );
    const url = response.data.result;
    setIsDisabled(false);

    // FFTダウンロードフラグを立てる
    await Api.putAnalyzation({
      ...analyzationData,
      isFFTDownload: 1,
      updatedAt: dayjs().valueOf(),
      updatedBy: id
    });

    if (!url) return;
    window.open(url);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const clinic = clinicData ? clinicData[0] : null;
  const [lang, setLang] = useState<"ja" | "en">(clinic?.reportLang ?? "ja");

  useEffect(() => {
    setLang(clinic?.reportLang ?? "ja");
  }, [clinic]);

  const rows = useMemo(() => {
    if (!analyzationState) return [];
    const isAllowedBrowseAllReport = clinic?.isAllowedBrowseAllReport;
    const reportType = clinic?.reportType;
    const isAllowedFFTDownload = clinic?.isAllowedFFTDownload;
    let filteredAnalyzationDataColums = analyzationDataColums;
    if (['failed', 'requestForReMeasure'].includes(analyzationState.status)) {
      filteredAnalyzationDataColums = analyzationDataColums.filter((doc) => !['doctorA3', 'doctorA4', 'research', 'fftResult'].includes(doc.value));
    }
    if (!isAllowedBrowseAllReport) {
      const excludeColums = ["doctorA4", "doctorA3", "research"].filter(
        (column) => column !== reportType
      );
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => !excludeColums.includes(column.value)
      );
    }
    if (!isAllowedFFTDownload) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter(
        (column) => column.value !== "fftResult"
      );
    }
    if (!clinic?.isEnableAthens) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter((column) => column.value !== "athens");
    }
    if (!clinic?.isEnableSnoreQuestion) {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter((column) => column.value !== "snoreQuestion");
    }
    if (analyzationState?.status === 'failed') {
      filteredAnalyzationDataColums = filteredAnalyzationDataColums.filter((column) => !["doctorA3", "doctorA4", "research"].includes(column.value));
    }

    if (!analyzationState) return [];
    return filteredAnalyzationDataColums.map((column) => {
      const value = analyzationState[column.value as keyof Analyzation];
      if (column.value === "status") {
        return {
          title: column.label,
          value: value
            ? getAnalyzationStatusLabel(String(value) as AnalyzationStatus)
            : "",
        };
      }
      if (column.value === "measureStartDate") {
        return {
          title: column.label,
          value: value ? dayjs(value as number).format("YYYY年MM月DD日 HH:mm:ss") : "",
        };
      }
      if (column.value === "doctorA3") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => {
                  setOpenDocA3ReportModal(true);
                }}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "doctorA4") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => setOpenDocA4ReportModal(true)}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "research") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <MuiLink
                underline="none"
                onClick={() => {
                  setOpenReportModal(true);
                }}
                style={{ marginRight: 8 }}
              >
                レポート表示
              </MuiLink>
              <ArrowIcon />
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "fftResult") {
        return {
          title: column.label,
          value: (
            <FlexLinkWrap>
              <DownloadIcon />
              <MuiLink
                underline="none"
                onClick={() => {
                  handleDownloadSingleFFT(analyzationState);
                }}
                style={{ marginLeft: 8 }}
              >
                FFTファイルのダウンロード
              </MuiLink>
            </FlexLinkWrap>
          ),
        };
      }
      if (column.value === "patientNumber") {
        const patients = patientsResult?.result.Items;
        const patientNumber = patients
          ? patients.find((p) => p.id === analyzationState.patientId)
            ?.patientNumber
          : "";
        return {
          title: column.label,
          value: patientNumber ?? "",
        };
      }
      return {
        title: column.label,
        value: value as String ?? "",
      };
    });
  }, [analyzationState, clinic, patientsResult]);

  const patinetData = useMemo(() => {
    if (!patientsResult) return;
    return patientsResult?.result.Items.find(
      (patient) => patient.id === patientId
    );
  }, [patientsResult]);
  const affiliationData = useMemo(() => {
    if (!affiliation?.result?.affiliations) return;
    return affiliation?.result?.affiliations.Items?.find(
      (affiliation) => affiliation.id === patinetData?.affiliationId
    );
  }, [affiliation]);
  const attributeRows = useMemo(() => {
    if (!analyzationState) return [];
    return attributeColumns.filter((doc) => !(doc.value === "diseaseList" && analyzationState?.useOfDiseaseMedicalTreatment !== 1)).map((column) => {
      const key = Object.keys(analyzationState).find(
        (v) => v === column.value
      ) as keyof Analyzation;
      if (column.value === "diseaseList") {
        const diseaseList = analyzationState?.diseaseList
        ? typeof analyzationState?.diseaseList === "string"
          ? JSON.parse(analyzationState.diseaseList as unknown as string)
          : analyzationState.diseaseList
        : [];
      const diseases = diseaseList?.map((disease: any) => {
        return (
          <tr>
            <td>{disease?.other}</td>
            <td>{disease?.firstVisitDate}</td>
          </tr>
        );
      });
      return {
        title: column.label,
        value: (
          <>
          <table>
             <tr>
              <th style={{ width: "10%" }}>主疾患</th>
            </tr>
            <tr>
              <th style={{fontWeight: 500, width: '30' }}>{diseaseList ? diseaseList[0]?.diseaseName ?? "" : ""}</th>
            </tr>
            </table>
            <table>
            <tr>
              <th style={{ width: "10%" }}>疾病</th>
              <th style={{ width: "20%" }}>初診日</th>
            </tr>
            {diseases}
          </table>
          </>
        ),
      };
      } else {
        return {
          title: column.label,
          value: String(analyzationState[key] ?? ""),
        };
      }
    });
  }, [analyzationState]);

  const analyzationResultRow = useMemo(() => {
    return analyzationResultColumns.map((column) => {
      let value = analyzationResultData ? String(getSleepCycle(column.value as keyof AnalyzationResult, analyzationResultData)) : "";
      if (column.value === "firstSleepCycleDeltaPow") {
        // "第1睡眠周期の総δパワー値"
        value = String(roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower)) ?? "";
      } else if (column.value === "firstSleepCycleTime") {
        // "第1睡眠周期時間(REMは除く)"
        value = String(roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalSleepCycleTime)) ?? "";
      } else if (column.value === "firstSleepCycleDeltaPowParMin") {
        // "第1睡眠周期のδパワー値/ 1分あたり"
        value = String(roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin)) ?? "";
      } else if (column.value === "totalNonremDeltaPowParMin") {
        //  "第1睡眠周期/睡眠時間"
        value = String(roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.deltaPowParTotalDeltaPow)) ?? "";
      } else if (column.value === "firstSleepCycleDeltaPowParToalDeltaPow") {
        // SPTのδパワー値/1分あたり
        value = String(roundToSecondDecimalPlace(analyzationResultData?.totalNonremDeltaPowParMin)) ?? "";
      } else if (column.value === "beforeW2hWIndex") {
        value = String(roundToSecondDecimalPlace(analyzationResultData?.beforeW2hWIndex)) ?? "";
      }  else if (column.value === "beforeW2hWaso") {
        value = String((analyzationResultData?.beforeW2hWaso ?? 0)/ 120 * 100 > 100 ? 100 :roundToSecondDecimalPlace( (analyzationResultData?.beforeW2hWaso ?? 0)/ 120 * 100)) ?? "";
      }
      return {
        title: column.label,
        value: value
      }
    });
  }, [analyzationResultData]);

  useEffect(() => {
    if (!analyzationsData) return;
    // selectedListの格納されているanalyzationデータをselctedAnalyzationに格納
    const selectedAnalyzation = analyzationsData.filter(
      (analyzation) => selectList.includes(analyzation.id)
    );
    // selectedAnalyzationのanalyzationResultを格納
    setSelectedAnalyzations(selectedAnalyzation ?? []);
  }, [selectList, analyzationsData]);

  const setTimeData = async () => {
    if (!selectedAnalyzations) return;
    // analyzationResultをそれぞれ取得する
    setIsLoadingTimeSerisData(true);
    const analyzationResult = await Promise.all(
      selectedAnalyzations.map(async (analyzation) => {
        const result = await Api.getAnalyzationResult(analyzation.id);
        return result.data.result.Items[0] as AnalyzationResult;
      })
    );
    setIsLoadingTimeSerisData(false);
    setSelectedAnalyzationResults(analyzationResult);
  };
  const rejectMessageList = useMemo(() => {
    const rejectMessageList = analyzationState?.rejectMessageList;
    if (!rejectMessageList) return [];
    const message = Array.from(new Set(rejectMessageList.map((doc) => doc.userMessage))).join("\n");
    return [
      { title: "状態", value: getAnalyzationStatusLabel(analyzationState?.status as AnalyzationStatus) },
      { title: "状態詳細", value: message }
    ]
  }, [analyzationState]);

  const handleDownLoadCSV = async () => {
    // selectedListからanalyzatioの値を取得
    if (!analyzationsData) return;
    // selectedListの格納されているanalyzationデータをselctedAnalyzationに格納
    const sortColumn = sortModel[0].field as keyof Analyzation;
    const selectedAnalyzation = analyzationsData.filter((analyzation) => selectList.includes(analyzation.id) && analyzation.status === "confirmed");
    if(sortColumn){
      selectedAnalyzation.sort((a: Analyzation, b: Analyzation) => {
        if (sortModel[0].sort === 'asc') {
          return (a[sortColumn] ?? '') > (b[sortColumn] ?? '') ? 1 : -1;
        } else {
          return (a[sortColumn] ?? '') < (b[sortColumn] ?? '') ? 1 : -1;
        }
      });
    }
    const isSEASD = selectedAnalyzation.some((analyzation) => analyzation.moduleName === 'SEAS-D');
    const isSEASG = selectedAnalyzation.some((analyzation) => analyzation.moduleName === 'SEAS-G');
    if (isSEASD && isSEASG) {
      setSnackState({
        open: true,
        message: "SEAS-DとSEAS-Gの結果は同時にCSV出力できません",
        success: false,
      });
      return;
    }
    const analyzationResult = await Promise.all(selectedAnalyzation.map(async (analyzation) => {
      const result = await Api.getAnalyzationResult(analyzation.id);
      const sortedFilteredResult = result.data.result.Items.filter((item: AnalyzationResult) => item.isDeleted === 0).sort((a: AnalyzationResult, b: AnalyzationResult) => 
      a.createdAt > b.createdAt ? -1 : 1);
      return sortedFilteredResult[0] as AnalyzationResult;
    }
    ));
    const list = convertToCSVFormat(
      selectedAnalyzation,
      patientsResult?.result.Items ?? [],
      affiliation?.result.affiliations.Items ?? [],
      analyzationResult,
      diseases ?? [],
      clinic,
      affiliation?.result.industries.Items ?? [],
      affiliation?.result.occupations.Items ?? [],
    );
    exportCSV(list, `analyzation_${dayjs().format("YYYYMMDD-HHmmss")}`)
  }

  const downloadFTTFiles = async () => {
    const formatDateToyyyymmddhhmmss = formatDateToYYYYMMDDHHMMSS(new Date());
    const fftDownloadedFolderName = 'fft_files_' + formatDateToyyyymmddhhmmss;
    //setDisabled(true);
    const selectedAnalyzationList: Analyzation[] = [];
    selectList.map(async (analyzationId) => {
      const analyzationData = analyzationsData.find(
        (analyzation) => analyzation.id === analyzationId
      );
      if (analyzationData?.status === AnalyzationStatus.CONFIRMED ||
        analyzationData?.status === AnalyzationStatus.WAITING_FOR_CONFIRM ||
        analyzationData?.status === AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT) {
        selectedAnalyzationList.push(analyzationData);
      }
    })

    // ファイルのダウンロードを並列に行うためのPromise配列を作成
    const downloadPromises = selectedAnalyzationList.map(async (selectedAnalyzation: any) => {
      //const response = await fetch(url);
      const fftUrl = selectedAnalyzation?.edfUrl.replace('.EDF', '.txt');
      const fftFileName = fftUrl.split('/').pop();
      const getSignedUrlResponse = await Api.getSignedUrl(
        fftFileName,
        selectedAnalyzation.patientId,
        selectedAnalyzation.clinicId,
        "get"
      );
      const url = getSignedUrlResponse.data.result;
      const response = await fetch(url);

      // FFTダウンロードフラグを立てる
      await Api.putAnalyzation({
        ...selectedAnalyzation,
        isFFTDownload: 1,
        updatedAt: dayjs().valueOf(),
        updatedBy: id
      });

      return { fileName: `${fftDownloadedFolderName}/` + fftFileName, data: await response.blob() };
    });

    // 全てのファイルのダウンロードが完了したら処理を実行
    const filesData = await Promise.all(downloadPromises);
    if (selectedAnalyzationList.length > 1) {
      // JSZipインスタンスを作成
      const zip = new JSZip();
      // zip化するファイルを追加
      filesData.forEach((fileData: any) => {
        zip.file(fileData.fileName, fileData.data);
      });
      // zipファイルを生成
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      // zipファイルをダウンロード
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${fftDownloadedFolderName}.zip`;
      downloadLink.click();
    } else if (selectedAnalyzationList.length === 1) {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(filesData[0].data);
      downloadLink.download = filesData[0].fileName.split('/').pop() || filesData[0].fileName;
      downloadLink.click();
    }
  }

  const timeSeriesTitle = useMemo(() => {
    // 患者番号
    // selectedAnalyzationsを新しい順に並び替え
    // 最初と最後のmeasureStartDateを取得
    // 最初と最後のmeasureStartDateを表示
    if (!selectedAnalyzations || selectedAnalyzations.length === 0) return "";

    const patinetId = selectedAnalyzations[0]?.patientId;
    const patinetData = patientsResult?.result.Items.find((patient) => patient.id === patinetId);
    const sortedAnalyzations = selectedAnalyzations.sort((a, b) => {
      if (a.measureStartDate > b.measureStartDate) {
        return -1;
      } else {
        return 1;
      }
    });
    const firstMeasureStartDate = sortedAnalyzations[0].measureStartDate;
    const lastMeasureStartDate = sortedAnalyzations[sortedAnalyzations.length - 1].measureStartDate;
    return `${patinetData?.patientNumber}-${dayjs(firstMeasureStartDate).format("YYMMDD")}-${dayjs(lastMeasureStartDate).format("YYMMDD")}`;
  }, [selectedAnalyzations, patientsResult]);

  const analyzationAge = useMemo(() => {
    if (!analyzationState) return "";
    const patients = patientsResult?.result.Items ?? [];
    const patient = patients.find((p) => p.id === analyzationState.patientId);
    if (!patient) return "";
    const birthDate = patient.birthday;
    const measureStartDate = analyzationState?.measureStartDate;
    if (!birthDate || !measureStartDate) return "";
    const birthDateYear = dayjs(birthDate).year();
    const measureStartDateYear = dayjs(measureStartDate).year();
    return measureStartDateYear - birthDateYear;
  }, [analyzationState, patientsResult]);

  if (loading) return <Progress />;
  return (
    <div>
      <BodyTitle>{"受診者ごとの解析情報一覧"}</BodyTitle>
      <FlexWrap style={{ marginTop: 16 }}>
        {clinic?.isGenerateTimeSeriesReport && (
          <Button
            children={"時系列レポート作成"}
            width={220}
            color="secondary"
            disabled={selectList.length === 0}
            onClick={async () => {
              if (selectList.length > 7) {
                window.alert('対象の選択肢を7個以下に設定してください');
                return;
              }
              const selectedAnalyzations = analyzationsData.filter((analyzation) => selectList.includes(analyzation.id));
              const isIncludeNotConfirmed = selectedAnalyzations?.find((analyzation) => analyzation.status !== AnalyzationStatus.CONFIRMED);
              if (isIncludeNotConfirmed) {
                window.alert('解析状態がレポート作成済みの解析データのみ選択してください');
                return;
              }
              setOpenTimeSeriesModal(true);
              setTimeData();
            }}
          />
        )}
        <ButtonWrap>
          <Button
            children={"PDFダウンロード"}
            width={210}
            color="secondary"
            disabled={selectList.length === 0}
            onClick={() => {
              if (selectList.length > 10) {
                setSnackState({
                  open: true,
                  message: `対象の選択肢を10個以下に設定してください`,
                  success: false,
                });
                setTimeout(() => {
                  setSnackState({
                    open: false,
                    message: `対象の選択肢を10個以下に設定してください`,
                    success: false,
                  });
                }, 3000);
                return;
              }
              const selectedAnalyzationList: Array<any> = [];
              for (let i = 0; i < selectList.length; i++) {
                if (selectedAnalyzations[i]?.status === AnalyzationStatus.CONFIRMED) {
                  selectedAnalyzationList.push(selectedAnalyzations[i]);
                } else {
                  setSnackState({
                    open: true,
                    message: `スタータスがレポート作成済みの解析のみ選択してください`,
                    success: false,
                  });
                  setTimeout(() => {
                    setSnackState({
                      open: false,
                      message: `スタータスがレポート作成済みの解析のみ選択してください`,
                      success: false,
                    });
                  }, 3000);
                  return;
                }
              }
              setOpenPdfDownloadModal(true);
            }}
          />
        </ButtonWrap>
        {clinic?.isAllowedFFTDownload && <ButtonWrap>
          <Button
            children={'FFTダウンロード'}
            width={220}
            color="secondary"
            disabled={selectList.length === 0}
            onClick={() => downloadFTTFiles()}
          />
        </ButtonWrap>}
        {clinic?.isAllowedTotalDonwload &&
          <ButtonWrap>
            <Button
              children={"CSVダウンロード"}
              width={180}
              color="secondary"
              onClick={() => handleDownLoadCSV()}
              disabled={selectList.length === 0}
            />
          </ButtonWrap>}
      </FlexWrap>
      <Box style={{ marginBottom: 16, display: 'flex' }} gap={4}>
        <SubTextWrap>
          <SubText fontcolor="black">
            {(patinetData && clinic?.isInputPatientName) ? `氏名：${patinetData.name ?? ''}` : "氏名："}
          </SubText>
        </SubTextWrap>
        <SubTextWrap>
          <SubText fontcolor="black">
            ID：{patinetData ? patinetData.patientNumber : ""}
          </SubText>
        </SubTextWrap>
        <SubTextWrap>
          <SubText fontcolor="black">
            生年月日：{patinetData ? patinetData.birthday : ""}
          </SubText>
        </SubTextWrap>
        <SubTextWrap>
          <SubText fontcolor="black">
            都道府県：{patinetData ? patinetData.prefecture : ""}
          </SubText>
        </SubTextWrap>
        <SubTextWrap>
          <SubText fontcolor="black">
            所属： {affiliationData ? affiliationData.name : ""}
          </SubText>
        </SubTextWrap>
      </Box>
      <MuiDataGrid
        analyzations={analyzationsData}
        handleDownloadEDF={handleDownloadEDF}
        patients={patientsResult?.result.Items ?? []}
        setOpen={setOpen}
        affiliations={affiliation?.result?.affiliations.Items ?? []}
        setAnalyzationState={setAnalyzationState}
        setSelectList={setSelectList}
        setOpenReportModal={setOpenReportModal}
        setIsOpenRejectModal={setIsOpenRejectModal}
        clinic={clinic}
        setOpenDocA4ReportModal={setOpenDocA4ReportModal}
        setOpenDocA3ReportModal={setOpenDocA3ReportModal}
        handleDownloadSingleFFT={handleDownloadSingleFFT}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={"解析情報"}
        maxWidth={"lg"}
        width={900}
      >
        <div style={{ marginTop: 8 }}>
          <HeadlessTable 
            rows={rows.length > 0 ? rows : []} 
            width={280}
            />
          <Accordion
            title="属性情報"
            handleChange={handleChange}
            children={
              <HeadlessTable
                width={280}
                rows={attributeRows.length > 0 ? attributeRows : []}
              />
            }
            expanded={expanded}
            panel={"panel1"}
          />
          <Accordion
            title="解析結果"
            handleChange={handleChange}
            children={
              <HeadlessTable
                rows={
                  analyzationResultRow.length > 0 ? analyzationResultRow : []
                }
                width={280}
              />
            }
            expanded={expanded}
            panel={"panel2"}
          />
        </div>
      </Modal>
      <SimpleBackdrop
        open={isDisabled}
        message={"ファイルをダウンロードしています..."}
      />
      <CustomizedSnackbar snackState={snackState} setSnackState={setSnackState} />
      <EditModal
        open={openReportModal}
        onClose={() => {
          setOpenReportModal(false);
        }}
        title={lang === 'ja' ? "睡眠レポート" : "Sleep Examination Report"}
        maxWidth={"lg"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace('.EDF', '')}_report`}
        component={clinic?.isAllowedBrowseAllLangReport && <SelectInput
          width={'150px'}
          items={[{ label: '日本語', value: 'ja' }, { label: 'English', value: 'en' }]}
          value={lang}
          onChange={(e: any) => setLang(e.target.value)}
        />}
      >
        <SleepAnalyticsDetail
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          clinic={clinic}
          lang={lang}
          type={analyzationState?.type}
          edfFileName={analyzationState?.edfFileName}
          patients={patientsResult?.result.Items ?? []}
          patientId={analyzationState?.patientId ?? ""}
        />
      </EditModal>
      {openPdfDownloadModal &&
        <PdfDownloadModal
          open={openPdfDownloadModal}
          onClose={() => {
            setOpenPdfDownloadModal(false);
            setFftDownload(false);
          }}
          fftDownload={fftDownload}
          title={"睡眠レポート"}
          maxWidth={"lg"}
          width={'calc(100% - 96px)'}
          isShowPrintButton={true}
          fileName={`睡眠レポート`}
          selectAnalyzationList={selectedAnalyzations}
        >
          {selectedAnalyzations.map(item => {
            const patients = patientsResult?.result.Items ?? [];
            const patient = patients.find((p) => p.id === item?.patientId);
            const birthDate = patient?.birthday;
            const measureStartDate = item?.measureStartDate;
            const birthDateYear = dayjs(birthDate).year();
            const measureStartDateYear = dayjs(measureStartDate).year();
            const age =  measureStartDateYear - birthDateYear;
            return(
              <SleepAnalyticsDetail
              analyzationId={item?.id ?? ""}
              openReportModal={true}
              age={Number(age) ?? 0}
              measureStartDate={item?.measureStartDate ?? 0}
              isPdfDownload={true}
              clinic={clinic}
              lang={lang}
              type={item?.type}
              edfFileName={item?.edfFileName}
              patients={patientsResult?.result.Items ?? []}
              patientId={item?.patientId ?? ""}
              anaylzation={item}
            />
            )
          })}
        </PdfDownloadModal>}
      <EditModal
        open={openTimeSeriesModal}
        onClose={() => {
          setOpenTimeSeriesModal(false);
        }}
        title={lang === "ja" ? `時系列レポート` : "Time-series report"}
        maxWidth={"lg"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={timeSeriesTitle}
        component={clinic?.isAllowedBrowseAllLangReport && <SelectInput
          width={'150px'}
          items={[{ label: '日本語', value: 'ja' }, { label: 'English', value: 'en' }]}
          value={lang}
          onChange={(e: any) => setLang(e.target.value)}
        />}
      >
        <Timeseries
          selectedAnalyzations={selectedAnalyzations}
          selectedAnalyzationResults={selectedAnalyzationResults}
          isLoadingTimeSerisData={isLoadingTimeSerisData}
          patients={patientsResult?.result.Items ?? []}
          affiliations={affiliation?.result?.affiliations.Items ?? []}
          averageItems={averageItems?.data.result.Items ?? []}
          lang={lang}
        />
      </EditModal>
      <EditModal
        open={openDocA4ReportModal}
        onClose={() => {
          setOpenDocA4ReportModal(false);
        }}
        title={lang === 'ja' ? "睡眠検査結果" : 'Result of Sleep Inspection'}
        maxWidth={"lg"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace('.EDF', '')}_dock_a4`}
        component={clinic?.isAllowedBrowseAllLangReport && <SelectInput
          width={'150px'}
          items={[{ label: '日本語', value: 'ja' }, { label: 'English', value: 'en' }]}
          value={lang}
          onChange={(e: any) => setLang(e.target.value)}
        />}
      >
        <SleepAnalyticsDocAReport
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openDocA4ReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          patientId={analyzationState?.patientId ?? ""}
          patients={patientsResult?.result.Items ?? []}
          clinic={clinicData ?? null}
          averageItems={averageItems?.data.result.Items ?? []}
          lang={lang}
          isImmediateAnalyize={analyzationState?.isImmediateAnalyize ?? 0}
          snoreQuestion={analyzationState?.snoreQuestion}
          analyzation={analyzationState}
          affiliation={affiliation?.result.affiliations.Items?.find((doc) => doc.id === analyzationState?.affiliationId) ?? null}
        />
      </EditModal>
      <EditWideModal
        open={openDocA3ReportModal}
        onClose={() => {
          setOpenDocA3ReportModal(false);
        }}
        title={lang === 'ja' ? "睡眠検査結果" : 'Result of Sleep Inspection'}
        maxWidth={"xl"}
        width={"calc(100% - 96px)"}
        isShowPrintButton={true}
        fileName={`${analyzationState?.edfFileName.replace('.EDF', '')}_dock`}
        component={clinic?.isAllowedBrowseAllLangReport && <SelectInput
          width={'150px'}
          items={[{ label: '日本語', value: 'ja' }, { label: 'English', value: 'en' }]}
          value={lang}
          onChange={(e: any) => setLang(e.target.value)}
        />}
      >
        <SleepAnalyticsDocA3Report
          analyzationId={analyzationState?.id ?? ""}
          openReportModal={openDocA3ReportModal}
          age={Number(analyzationAge) ?? 20}
          measureStartDate={analyzationState?.measureStartDate ?? 0}
          patientId={analyzationState?.patientId ?? ""}
          patients={patientsResult?.result.Items ?? []}
          clinic={clinicData ?? null}
          averageItems={averageItems?.data.result.Items ?? []}
          lang={lang}
          isImmediateAnalyize={analyzationState?.isImmediateAnalyize ?? 0}
          snoreQuestion={analyzationState?.snoreQuestion}
          analyzation={analyzationState}
        />
      </EditWideModal>
      <EditModal
        open={isOpenRejectModal}
        width={'calc(100% - 96px)'}
        onClose={() => {
          setIsOpenRejectModal(false);
          setSelectList([]);
        }}
        title={"解析メッセージ"}
        maxWidth={"md"}
      >
        <div style={{ marginTop: 16 }}>
          <HeadlessTable rows={rejectMessageList} width={150} />
        </div>
      </EditModal>
    </div>
  );
};

const FlexWrap = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 32,
});
const ButtonWrap = styled("div")({
  marginLeft: 8,
});

const Container = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "1200px",
}));

const FlexLinkWrap = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SubTextWrap = styled('div')`
width: 18%;
border-bottom: 1px solid #4B4B4B;
`;
